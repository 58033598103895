import { checkPermission } from '@/utils'
export const tableOption = {
  menuAlign: 'left',
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSize: 'small',
  border: true,
  column: []
}
